import { MenuItem } from '@escalate-backoffice/core/api-types';

export class AppMenu {
  public static pages: MenuItem[] = [
    {
      group: 'General',
      separator: true,
      items: [
        {
          icon: 'assets/icons/heroicons/outline/chart-pie.svg',
          label: 'Dashboard',
          route: 'dashboard',
        },
      ],
    },
    {
      group: 'Content Management',
      separator: true,
      items: [
        {
          icon: 'assets/icons/heroicons/outline/download.svg',
          label: 'Business Categories',
          route: '/business-categories',
        },
        {
          icon: 'assets/icons/heroicons/outline/gift.svg',
          label: 'Proximity Plans',
          route: '/proximity-plans',
          children: [
            { label: 'All Plans', route: '/proximity-plans' },
            { label: 'Medical Plans', route: '/proximity-plans/medical' },
            { label: 'Insecurity Plans', route: '/proximity-plans/insecurity' },
            { label: 'Urgent Assistance Plans', route: '/proximity-plans/urgent-assistance' },
          ],
        },
        {
          icon: 'assets/icons/heroicons/outline/gift.svg',
          label: 'Reports',
          route: '/reports',
        },
        {
          icon: 'assets/icons/heroicons/outline/gift.svg',
          label: 'SOS/Emergency Contacts',
          route: '/emergency-contacts',
        },
        {
          icon: 'assets/icons/heroicons/outline/moon.svg',
          label: 'Ad Banners',
          route: '/adbanners',
        },
      ],
    },
    {
      group: 'ACL',
      separator: true,
      items: [
        // {
        //   icon: 'assets/icons/heroicons/outline/x.svg',
        //   label: 'My Profile',
        //   route: '/profile',
        // },
        {
          icon: 'assets/icons/heroicons/outline/users.svg',
          label: 'Users',
          route: '/customers',
        },
        // {
        //   icon: 'assets/icons/heroicons/outline/users.svg',
        //   label: 'Users',
        //   route: '/staffs',
        // },
      ],
    },
  ];
}
