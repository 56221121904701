import {CanActivateFn, Router} from '@angular/router';
import {map, take} from "rxjs";
import {inject} from "@angular/core";
import {StorageService} from "@escalate-backoffice/modules/data-access";

export const authorizedGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const storage = inject(StorageService);

  return storage.getToken().pipe(
      take(1),
      map((token) => {
        return !token ? true : router.parseUrl('/');
      })
  );
};
