import { DragDropModule } from '@angular/cdk/drag-drop';
import { CdkScrollableModule, ScrollingModule } from '@angular/cdk/scrolling';
import { registerLocaleData } from '@angular/common';
import { provideHttpClient, withInterceptors, withJsonpSupport } from '@angular/common/http';
import en from '@angular/common/locales/en';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { IconDefinition } from '@ant-design/icons-angular';
import * as AllIcons from '@ant-design/icons-angular/icons';
import { ENV_IS_PRODUCTION, ENV_IS_STAGING } from '@escalate-backoffice/core/api-types';
import { errorHandlerInterceptor } from '@escalate-backoffice/core/error-handler';
import { API_URL } from '@escalate-backoffice/core/http-client';
import { MENU_PAGES } from '@escalate-backoffice/core/utils';
import { tokenInterceptor } from '@escalate-backoffice/modules/auth/utils';
import { NgZorroAntdModule } from '@escalate-backoffice/shared/ui';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyNgZorroAntdModule } from '@ngx-formly/ng-zorro-antd';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { defaultStoreProvider } from '@state-adapt/angular';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { NZ_ICONS } from 'ng-zorro-antd/icon';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { environment } from '../environments/environment';
import { AppMenu } from './app.menu';
import { appRoutes } from './app.routes';

registerLocaleData(en);

const antDesignIcons = AllIcons as {
  [key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map((key) => antDesignIcons[key]);

export const appConfig: ApplicationConfig = {
  providers: [
    { provide: API_URL, useValue: environment.apiUrl },
    { provide: ENV_IS_STAGING, useValue: environment.isStaging },
    { provide: ENV_IS_PRODUCTION, useValue: environment.production },
    { provide: MENU_PAGES, useValue: AppMenu.pages },
    { provide: NZ_I18N, useValue: en_US },
    { provide: NZ_ICONS, useValue: icons },
    provideRouter(appRoutes, withComponentInputBinding()),
    provideHttpClient(withInterceptors([tokenInterceptor, errorHandlerInterceptor]), withJsonpSupport()),
    provideAnimations(),
    defaultStoreProvider,
    importProvidersFrom([
      LoadingBarModule,
      LoadingBarHttpClientModule,
      LoadingBarRouterModule,
      NgScrollbarModule,
      AngularSvgIconModule.forRoot(),
      FontAwesomeModule,
      CdkScrollableModule,
      DragDropModule,
      ScrollingModule,
      NgZorroAntdModule,
      NgApexchartsModule,
      FormlyModule.forRoot(),
      FormlyNgZorroAntdModule,
      GoogleMapsModule,
    ]),
  ],
};
