import { Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { ErrorStore } from '@escalate-backoffice/core/error-handler';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgScrollbar } from 'ngx-scrollbar';
import { NgClass } from '@angular/common';
import { ThemeService } from '@escalate-backoffice/shared/ui';

@Component({
  standalone: true,
  imports: [RouterModule, LoadingBarModule, NgScrollbar, NgClass],
  selector: 'ea-root',
  template: `
    <div [ngClass]="{ dark: themeService.isDark }">
      <span class="sr-only">Welcome {{ title }}</span>
      <ng-scrollbar />
      <ngx-loading-bar height="5px" color="#FF570F" />
      <router-outlet />
    </div>
  `,
})
export class AppComponent {
  title = 'control';
  themeService = inject(ThemeService);
  private _errorStore = inject(ErrorStore);

  constructor() {
    this._errorStore.vm$.pipe(takeUntilDestroyed()).subscribe();
  }
}
