import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { StorageService } from '@escalate-backoffice/modules/data-access';
import { switchMap, take } from 'rxjs';

export const tokenInterceptor: HttpInterceptorFn = (req, next) => {
  const storage = inject(StorageService);

  return storage.getToken().pipe(
    take(1),
    switchMap((token) => {
      if (token) {
        // if req is jsonp
        if (req.method === 'JSONP') {
          return next(req);
        }

        // if req is not jsonp
        return next(
          req.clone({
            headers: req.headers
              .append('Content-Type', 'application/json')
              .append('Accept', 'application/json')
              // .append('Access-Control-Allow-Origin', '*')
              .append('Authorization', `Bearer ${token}`),
          }),
        );
      }

      return next(
        req.clone({
          headers: req.headers.append('Content-Type', 'application/json').append('Accept', 'application/json'),
          // .append('Access-Control-Allow-Origin', '*'),
        }),
      );
    }),
  );
};
