import { Route } from '@angular/router';
import { setPageTitle } from '@escalate-backoffice/core/utils';
import { authorizedGuard, unauthorizedGuard } from '@escalate-backoffice/modules/auth/utils';

export const appRoutes: Route[] = [
  {
    path: '',
    canActivate: [unauthorizedGuard],
    loadComponent: () => import('@escalate-backoffice/shared/angular-twd').then((e) => e.AngularTwdComponent),
    children: [
      {
        path: 'plans',
        redirectTo: 'proximity-plans',
      },

      {
        path: '',
        loadChildren: () =>
          import('@escalate-backoffice/modules/dashboard/feature-dashboard').then((e) => e.featureDashboardRoutes),
        title: setPageTitle('Dashboard'),
      },
      {
        path: 'business-categories',
        loadChildren: () =>
          import('@escalate-backoffice/modules/business-categories/feature-business-categories').then(
            (e) => e.featureBusinessCategoriesRoutes,
          ),
        title: setPageTitle('Business Categories'),
      },
      {
        path: 'proximity-plans',
        loadChildren: () =>
          import('@escalate-backoffice/modules/proximity-plans/feature-proximity-plans').then(
            (e) => e.featureProximityPlansRoutes,
          ),
        title: setPageTitle('Proximity Plans'),
      },
      {
        path: 'reports',
        loadChildren: () =>
          import('@escalate-backoffice/modules/reports/feature-reports').then((e) => e.featureReportsRoutes),
        title: setPageTitle('Reports'),
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('@escalate-backoffice/modules/settings/feature-settings').then((e) => e.featureSettingsRoutes),
        title: setPageTitle('Settings'),
      },
      {
        path: 'staffs',
        loadChildren: () =>
          import('@escalate-backoffice/modules/staffs/feature-staffs').then((e) => e.featureStaffsRoutes),
        title: setPageTitle('Staffs'),
      },
      {
        path: 'customers',
        loadChildren: () =>
          import('@escalate-backoffice/modules/customers/feature-customers').then((e) => e.featureCustomersRoutes),
        title: setPageTitle('Customers'),
      },
      {
        path: 'profile',
        loadChildren: () =>
          import('@escalate-backoffice/modules/auth/feature-profile').then((e) => e.featureProfileRoutes),
        title: setPageTitle('Profile'),
      },
      {
        path: 'adbanners',
        loadChildren: () =>
          import('@escalate-backoffice/modules/adbanners/feature-adbanners').then((e) => e.featureAdbannersRoutes),
        title: setPageTitle('Ad Banners'),
      },
      {
        path: 'emergency-contacts',
        loadChildren: () =>
          import('@escalate-backoffice/modules/emergency-numbers/feature-emergency-numbers').then(
            (e) => e.modulesEmergencyNumbersFeatureEmergencyNumbersRoutes,
          ),
        title: setPageTitle('Emergency Contacts'),
      },
    ],
  },
  {
    path: 'auth',
    canActivate: [authorizedGuard],
    loadChildren: () => import('@escalate-backoffice/modules/feature-auth').then((e) => e.featureAuthRoutes),
  },
  {
    path: 'errors',
    loadChildren: () => import('@escalate-backoffice/core/error-pages').then((e) => e.errorPagesRoutes),
  },
  {
    path: '**',
    redirectTo: '/errors/not-found',
  },
];
